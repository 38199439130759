export class Metrics {
  constructor(
      public lvl: int,
      public exp: int,
      public maxLife: int,
              public life: int,
              public readonly name: string) {

  }


}
